$primary1: #eb528a;
$primary2: #c4145e;
$primary3: #840037;
$primary4: #d90000;
$primary4: #d90000;
$blues: #6183e0;
$greens: #6dd291;
$yellows: #dab43f;
$white: #fff;
$black: #000;
$hash: #393939;
$hash2: #767676;
$hash3: #ddd;
$lighthash: #ececec;
$background: #f2f3f9;
$background1: #fdfdfd;
