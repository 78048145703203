@import "./assets/sass/fonts.scss";
@import "./assets/sass/theme.scss";
body,
html {
  font-family: "f1";
  margin: 0;
  padding: 0;
  background: $background;
}
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: rgb(230, 230, 230);
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.1);
}
.flex-ac-jc {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-ac-jb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-ae-jb {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.flex-ae-jc {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.txt-primary1 {
  color: $primary1;
}
.txt-primary2 {
  color: $primary2;
}
.txt-primary3 {
  color: $primary3;
}
.txt-primary4 {
  color: $primary4;
}
.txt-blues {
  color: $blues;
}
.txt-greens {
  color: $greens;
}
.txt-hash2 {
  color: $hash2;
}
.txt-yellows {
  color: $yellows;
}
.txt-background {
  color: $background;
}
.txt-background1 {
  color: $background1;
}
.bg-primary1 {
  background: $primary1;
}
.bg-primary2 {
  background: $primary2;
}
.bg-primary3 {
  background: $primary3;
}
.bg-primary4 {
  background: $primary4;
}
.bg-blues {
  background: $blues;
}
.bg-greens {
  background: $greens;
}
.bg-yellows {
  background: $yellows;
}
.bg-hash2 {
  background: $hash2;
}
.bg-background {
  background: $background;
}
.bg-background1 {
  background: $background1;
}
.bg-danger-gradient {
  background-image: linear-gradient(
    to bottom right,
    #f1bf64 0%,
    #f71d36 100%
  ) !important;
}
.bg-primary-gradient {
  background: linear-gradient(to bottom right, #a29bff 0%, #6259ca) !important;
}
.bg-secondary-gradient {
  background: linear-gradient(
    to bottom right,
    #9070ff 0%,
    #ff5d9e 100%
  ) !important;
}
.bg-success-gradient {
  background: linear-gradient(
    to bottom right,
    #4be8d4 0%,
    #129bd2 100%
  ) !important;
}
.bg-prim-gradient {
  background: linear-gradient(
    to bottom right,
    #6a4be8 0%,
    #bc58ff 100%
  ) !important;
}
.w-90 {
  width: 90%;
}
.menu-icon {
  width: 10px;
}
.comn-btn {
  font-size: 13px;
  color: $white;
  font-family: "f2";
  border: 0;
  border-radius: 5px;
  text-decoration: none;
  &:hover {
    filter: grayscale(0.1) brightness(0.8);
    color: $white;
  }
}
.menu-bar {
  width: 230px;
  height: 100vh;
  position: fixed;
  overflow-y: scroll;
  left: 0;
  z-index: 2;
  background: $white;
  .top-head {
    position: fixed;
    z-index: 11;
    background: $white;
    left: 0;
    height: 60px;
    border: 1px solid $background;
    width: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 5px rgb(229 228 230 / 48%);
    text-decoration: none;
    h5 {
      color: $primary2;
      font-family: "f2";
      font-size: 20px;
      margin-bottom: 0;
    }
  }
  ul {
    padding: 0;
    border-radius: 5px;
    margin-bottom: 5px;
    li {
      list-style: none;
      .menu-list {
        align-items: center;
        display: flex;
        font-size: 14px;
        font-family: "f1";
        text-decoration: none;
        padding: 14px 20px;
        transition: 0.3s all linear;
        color: $primary2;
        position: relative;
        opacity: 0.9;
        img {
          width: 19px;
          margin-right: 10px;
          margin-left: 8px;
        }
        &::after {
          position: absolute;
          content: "";
          right: 0;
          top: 0;
          width: 0px;
          height: 100%;
          background-color: $primary4;
          transition: 0.1s all linear;
        }
        &:hover {
          background-color: $background;
          transition: 0.3s all linear;
          opacity: 1 !important;
          color: $primary4;
          img {
            opacity: 1 !important;
            filter: invert(0) contrast(20);
          }
          &::after {
            width: 3px;
            transition: 0.1s all linear;
          }
        }
      }
      .active {
        transition: 0.3s all linear;
        opacity: 1 !important;
        color: $primary4;
        img {
          opacity: 1 !important;
          filter: invert(0) contrast(20);
        }
        &::after {
          width: 3px;
          transition: 0.1s all linear;
        }
      }
    }
  }
}
.menu-bar1 {
  width: 230px;
  height: 100vh;
  position: fixed;
  overflow-y: scroll;
  left: 0;
  z-index: 1;
  background: $white;
  .top-head {
    position: fixed;
    z-index: 11;
    background: $white;
    left: 0;
    height: 60px;
    border: 1px solid $background;
    width: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 5px rgb(229 228 230 / 48%);
    text-decoration: none;
    h5 {
      color: $hash;
      font-family: "f2";
      font-size: 20px;
      margin-bottom: 0;
    }
  }
  ul {
    padding: 0;
    border-radius: 5px;
    margin-bottom: 5px;
    li {
      list-style: none;
      .menu-list1 {
        align-items: center;
        display: flex;
        font-size: 14px;
        font-family: "f1";
        text-decoration: none;
        padding: 14px 20px;
        transition: 0.3s all linear;
        color: $hash2;
        position: relative;
        opacity: 0.9;
        border: 0;
        background: transparent;
        width: 100%;
        img {
          width: 19px;
          margin-right: 10px;
          margin-left: 8px;
        }
        &::after {
          position: absolute;
          content: "";
          right: 0;
          top: 0;
          width: 0px;
          height: 100%;
          background-color: $hash;
          transition: 0.1s all linear;
        }
        &:hover {
          background-color: $background;
          transition: 0.3s all linear;
          opacity: 1 !important;
          color: $hash;
          img {
            opacity: 1 !important;
            filter: grayscale(0.7);
          }
          &::after {
            width: 3px;
            transition: 0.1s all linear;
          }
        }
      }
      .active {
        transition: 0.3s all linear;
        opacity: 1 !important;
        color: $hash;
        background-color: $background;
        img {
          opacity: 1 !important;
          filter: grayscale(0.7);
        }
        &::after {
          width: 3px;
          transition: 0.1s all linear;
        }
      }
    }
  }
}
.header-rit-top {
  background: $white;
  height: 60px;
  box-shadow: 0 1px 5px rgb(229 228 230 / 48%);
  z-index: 1;
  .header-logout {
    height: 45px;
    width: 100%;
    border: 0;
    text-align: left;
    padding-left: 15px;
  }
  h4 {
    font-family: "f3";
    margin-bottom: 0;
    font-size: 17px;
    text-transform: uppercase;
  }
  .header-right {
    height: 45px;
    a,
    .notif-btn {
      width: 32px;
      height: 32px;
      border-radius: 25px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $white;
      box-shadow: 0px 3px 4px -1px #aaa;
      margin: 0 10px;
      cursor: pointer;
      transition: 0.2s all linear;
      position: relative;
      .notif-status {
        position: absolute;
        top: 6px;
        right: 6px;
        width: 7px;
        height: 7px;
        border-radius: 20px;
        background: $primary2;
        overflow: hidden;
      }
      svg {
        width: 20px;
      }
      &:hover {
        background-color: $primary2;
        transition: 0.2s all linear;
        svg {
          filter: invert(1);
        }
      }
    }
  }
}
.out-left {
  margin-left: 230px;
  background-color: $background;
}

.dash-box {
  border: 0;
  border-radius: 4px;
  padding: 10px;
  background-color: $white;
  box-shadow: 0 1px 5px rgb(229 228 230 / 48%);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  button {
    width: 35px;
    height: 35px;
    margin-bottom: 10px;
    border-radius: 25px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
    box-shadow: 0px 3px 4px -1px #aaa;
    border: 0;
    svg {
      width: 30px;
      filter: invert(1);
    }
  }
  h6 {
    font-size: 16px;
    font-family: "f1";
    color: rgb(133, 133, 133);
    margin-bottom: 0px;
  }
  h5 {
    font-size: 19px;
    font-family: "f2";
    margin: 3px 0 5px;
    color: $hash;
  }
  h3 {
    font-size: 30px;
    font-family: "f2";
    color: #ababab;
    margin-bottom: 0;
  }
}
.custom-table {
  width: 100%;
  border: 1px solid $hash3;
  thead {
    tr {
      background: $background;
      th {
        color: $hash;
        font-size: 14px;
        font-family: "f1";
        padding: 8px 12px;
        border-bottom: 1px solid $hash3;
        border-right: 1px solid $hash3;
        &:first-child {
          text-align: center;
        }
      }
    }
  }
  tbody {
    tr {
      background: $background1;
      td {
        background: transparent;
        color: $hash;
        font-size: 14px;
        font-family: "f1";
        padding: 8px 12px;
        border-bottom: 1px solid $hash3;
        border-right: 1px solid $hash3;
        min-height: 47px;
        &:first-child {
          width: 10px;
          text-align: center;
        }
        &:last-child {
          border-right: 0 !important;
          width: 100px;
        }
        img {
          width: 70px;
        }
      }
      &:nth-child(even) {
        background: $background;
      }
      .active-deact-btn {
        width: 105px;
        text-align: center;
        padding: 5px 0;
      }
    }
  }
}

.custom-table_new {
  width: 100%;
  border: 1px solid $hash3;
  thead {
    tr {
      background: $background;
      th {
        color: $hash;
        font-size: 14px;
        font-family: "f1";
        padding: 8px 12px;
        border-bottom: 1px solid $hash3;
        border-right: 1px solid $hash3;
        // &:first-child {
        //   text-align: center;
        // }
      }
    }
  }
  tbody {
    tr {
      background: $background1;
      td {
        background: transparent;
        color: $hash;
        font-size: 14px;
        font-family: "f1";
        padding: 8px 12px;
        border-bottom: 1px solid $hash3;
        border-right: 1px solid $hash3;
        min-height: 47px;
        // &:first-child {
        //   width: 10px;
        //   text-align: center;
        // }
        // &:last-child {
        //   border-right: 0 !important;
        //   width: 100px;
        // }
        img {
          width: 70px;
        }
      }
      &:nth-child(even) {
        background: $background;
      }
      .active-deact-btn {
        width: 105px;
        text-align: center;
        padding: 5px 0;
      }
    }
  }
}
.edit-btn-svg {
  width: 29px !important;
  height: 27px !important;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  display: inline-flex;
  svg {
    width: 13px;
  }
}
.table-modal {
  .modal-dialog {
    display: flex;
    align-items: center;
    min-height: calc(100% - var(--bs-modal-margin) * 2);
  }
}

.modal-err {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  margin-left: 0%;
  margin-top: 0%;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5%;
  .in-modal-box {
    background: rgba(255, 255, 255, 1);
    max-width: 100%;
    border: 0px solid #000;
    box-shadow: 0px 0px 5px #000;
    padding: 20px 50px;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    p {
      font-size: 15px;
      padding-right: 5px;
    }
  }
}
.modal-label-input {
  position: relative;
  h4 {
    font-family: "f3";
    font-size: 16px;
    margin-top: 10px;
  }
  label {
    font-size: 13px;
    color: $primary1;
    margin-bottom: 0;
    font-family: "f1";
  }
  input,
  select,
  textarea,
  .address-request {
    font-size: 13px;
    color: $black;
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    border-bottom: 1px solid #c7c7c7 !important;
    margin-bottom: 15px;
    border: 0;
    font-family: "f2";
    height: 35px;
    &::placeholder {
      font-family: "f1";
      opacity: 0.7;
      font-size: 13px;
    }
  }
  textarea {
    max-height: 50px !important;
  }
  .eye-btn {
    position: absolute;
    bottom: 21px;
    right: 10px;
    display: grid;
    border: 0;
    background: transparent;
    .log-in-eye {
      width: 20px;
    }
  }
  .err {
    font-size: 15px;
    font-size: 12px;
    color: #0008ff;
    position: absolute;
    right: 0;
    margin-bottom: 0;
    bottom: -3px;
  }
}
.table-In-p {
  position: relative;
  margin-bottom: 0;
}
.in-modal-box {
  .err {
    font-size: 12px;
    color: #0008ff;
    bottom: -3px;
  }
}
.search-input {
  input {
    border: 1px solid $hash2;
    &::placeholder {
      font-size: 13px;
      font-family: "f1";
      opacity: 0.6;
    }
  }
}
:focus-visible {
  outline: 0;
}
.textarea-height {
  height: auto !important;
}
.custom-pagi-nation {
  .page-item {
    .page-link {
      background: $primary1;
      border: 0;
      border-radius: 100% !important;
      width: 25px;
      height: 25px;
      align-items: center;
      justify-content: center;
      padding: 0;
      display: flex;
      margin: 0 5px;
      color: $white;
      opacity: 0.6;
      font-size: 10px;
      &:first-child,
      :last-child {
        border-radius: 100%;
      }
      &:hover {
        filter: brightness(0.4) contrast(1.2);
      }
    }
  }
  .active {
    .page-link {
      background: $primary2;
      opacity: 1;
      width: 30px;
      height: 30px;
      font-size: 14px;
    }
  }
}
.download-btn {
  width: 85px;
  height: 30px;
  border-radius: 3px;
  color: #fff;
  border: 0;
  font-size: 14px;
  font-family: "f1";
}
.wid {
  width: 150px;
  // margin: auto;
}
.ad-rol-a {
  text-decoration: none;
  &:hover {
    color: #fff;
  }
}
.prev-Image {
  border: 1px solid $background;
  background-color: #eff6ff;
  img {
    width: 100% !important;
    display: block;
    margin: auto;
  }
}
.bread-crumb {
  svg {
    width: 5px;
  }
  li {
    list-style: none;
    color: $primary2;
    font-size: 13px;
    font-family: "f1";
    a {
      font-size: 13px;
      color: $primary2;
      text-decoration: none;
      opacity: 0.6;
      svg {
        width: 10px;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
}
.permission-text {
  font-family: "f2";
  font-size: 14px;
}
.avatar-img {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 100%;
}
.roles-page {
  .form-group {
    h6 {
      font-family: "f2";
      font-size: 14px;
      margin-top: 10px;
    }
    .form-check {
      .form-checks-label {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: "f1";
        color: $hash2;
        margin-bottom: 10px;
        .form-checks-input,
        input[type="checkbox"] {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background: $white;
            border: 2px solid $hash;
            width: 15px;
            height: 15px;
            border-radius: 2px;
          }
          &:checked::before {
            content: "";
            width: 15px;
            height: 15px;
            border-radius: 2px;
            border: 3px solid $greens;
            background-color: $primary2;
            font-size: 12px;
            padding: 0;
            margin: auto;
          }
        }
      }
    }
  }
}
.logo {
  width: 36px;
  margin-right: 8px;
}
.lft-log {
}
.tab-appoinment {
  width: 270px;
  li {
    button {
      font-family: "f2";
      font-size: 14px;
      color: $primary1 !important;
    }
  }
}
.message-txt {
  height: auto !important;
}
.log-in {
  .left-log-in {
    position: relative;
    .log-in-left {
      height: auto !important;
      position: absolute;
      bottom: 0;
      width: 100%;
      img {
        width: 90%;
        margin: auto;
        display: flex;
      }
    }
  }
}
.log-logo {
  width: 60px;
}
.login-box {
  box-shadow: 2px 2px 1px 1px #e0e0e0;
  .logo-name {
    font-family: "f3";
    font-size: 21px;
    margin-top: 10px;
    color: $primary2;
  }
}
.sign-in-text {
  font-family: "f2";
  font-size: 16px;
}
.welcome-sign {
  font-family: "f1";
  font-size: 15px;
  margin-top: 5px;
  opacity: 0.5;
}
.chooseTime {
  font-size: 11px;
  font-weight: 400;
  border-radius: 0;
  border: 0;
  padding: 5px;
  margin-bottom: 5px;
  color: #fff;
  background: #6dd291;
  margin-right: 5px;
  filter: grayscale(0.2) brightness(0.8);
}
.choosedTime {
  font-size: 11px;
  font-weight: 400;
  border-radius: 0;
  border: 0;
  padding: 5px;
  margin-bottom: 5px;
  color: #fff;
  background: #6dd291;
  margin-right: 5px;
}
.chooseDate {
  font-size: 11px;
  font-weight: 500;
  border-radius: 0;
  border: 0;
  padding: 5px;
  margin-bottom: 5px;
  color: #fff;
  margin-right: 5px;
  filter: grayscale(0.2) brightness(0.8);
  background: #eb528a;
}
.choosedDate {
  font-size: 11px;
  font-weight: 500;
  border-radius: 0;
  border: 0;
  padding: 5px;
  margin-bottom: 5px;
  color: #fff;
  margin-right: 5px;
  background: #eb528a;
}
// .notif-btn{
//   width: ;
// }
